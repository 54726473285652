import React from "react";

export default function Pagination({ currentPage, totalPages, goToPage }) {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;
  const getPageNumbers = () => {
    if (totalPages <= 3)
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    if (currentPage === 1) return [1, 2, 3];
    if (currentPage === totalPages)
      return [totalPages - 2, totalPages - 1, totalPages];

    return [currentPage - 1, currentPage, currentPage + 1];
  };
  const pageNumbers = getPageNumbers();
  return (
    <div className="tds-pagination">
      <span
        className={!isFirstPage ? "active" : "inactive"}
        title={!isFirstPage ? "First Page" : ""}
        onClick={() => {
          !isFirstPage && goToPage(1);
        }}
      >
        &lt;&lt;
      </span>
      <span
        className={!isFirstPage ? "active" : "inactive"}
        title={!isFirstPage ? "Previous Page" : ""}
        onClick={() => {
          !isFirstPage && goToPage(currentPage - 1);
        }}
      >
        &lt;
      </span>
      {pageNumbers.map((page, idx) => (
        <span
          key={idx}
          className={`${page === currentPage ? "selected" : "active"}`}
          title={`Go to Page ${page}`}
          onClick={() => {
            goToPage(page);
          }}
        >
          {page}
        </span>
      ))}
      <span
        className={!isLastPage ? "active" : "inactive"}
        title={!isLastPage ? "Next Page" : ""}
        onClick={() => {
          !isLastPage && goToPage(currentPage + 1);
        }}
      >
        &gt;
      </span>
      <span
        className={!isLastPage ? "active" : "inactive"}
        title={!isLastPage ? "Last Page" : ""}
        onClick={() => {
          !isLastPage && goToPage(totalPages);
        }}
      >
        &gt;&gt;
      </span>
    </div>
  );
}
