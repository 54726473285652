const fetchData = async (query, firm) => {
  if (query.length > 2048) {
    return {
      success: false,
      data: null,
      error: "max char error",
    };
  }
  const fullUrl = '/api/v2/search-proxy';

  try {
    const response = await fetch(fullUrl, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ query: `/firm/${firm}/contents${query}` }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      return {
        success: false,
        data: null,
        error: "something went wrong",
      };
    }
    const data = await response.json();
    if (
      !data._embedded.contentDTOes ||
      (Array.isArray(data._embedded.contentDTOes) &&
        data._embedded.contentDTOes.length === 0)
    ) {
      return {
        success: false,
        data: data,
        error: "no data",
      };
    }

    return {
      success: true,
      data,
      error: null,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
      error: "something went wrong",
    };
  }
};

export default fetchData;
