import { REST_V3_API_URL } from "../constants/Rest.constants";
import HttpClient from "./HttpClient";

export const getSort = (sort) => {
  switch (sort) {
    case "doctype":
      return "&sort=bluematrixXmlDescr,asc";
    case "analyst":
      return "&sort=teamMemberFirstName,asc";
    case "title":
      return "&sort=title,asc";
    default:
      return "";
  }
};
export const getProjection = (currentProjection) => {
  switch (currentProjection) {
    case "strategy":
      return "ficmContent";
    case "equity-research-ca":
      return "eqCaContent";
    case "equity-research-us":
      return "eqUsContent";
    case "equity-research-wa":
      return "eqWrgContent";

    default:
      return "ficmContent";
  }
};

export const findLegacyIdAnalyst = (newData, legacyData) => {
  return newData
    .map((id) => {
      const match = legacyData.find((item) => item.bmIdCowen === id);
      return match && match.bmIdSt !== null ? `${id},${match.bmIdSt}` : null;
    })
    .filter((item) => item !== null)
    .join(",");
};

export const findLegacyIdIndustry = (newData, legacyData) => {
  return newData
    .map((id) => {
      const match = legacyData.find((item) => item.indIdCowen === id);
      return match && match.indIdSt !== null
        ? `${id},${match.indIdSt}`
        : `${id}`;
    })
    .filter((item) => item !== null)
    .join(",");
};



export const  getCookie = (key) =>  {
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}



export const getV3URL = async () => {
  return  await HttpClient.get(REST_V3_API_URL);
}