import { onCLS, onFID, onLCP } from 'web-vitals';
import ReactGA from "react-ga4";

function sendToGoogleAnalytics({ name, value, id }) {
  ReactGA.event({
    action: name,
    category: 'Web Vitals',
    label: id,
    nonInteraction: true,
    value: value
  });
}

export const reportWebVitals = () => {
  if (ReactGA.isInitialized) {
    onCLS(sendToGoogleAnalytics);
    onFID(sendToGoogleAnalytics);
    onLCP(sendToGoogleAnalytics);
  }
};