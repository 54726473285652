import React, { Component } from 'react';
import { Link } from "react-router-dom";
import FormatedDateComponent from '../../misc/formated-date/FormatedDate.component';
import MailtoComponent from '../../misc/mailto/Mailto.component';
import DownloadFileService from '../../../services/DownloadFile.service';
import UtilsService, { executeInnerHtml } from '../../../services/Utils.service';
import {
    LINK_PUBLICATION_PAGE,
    LINK_PATH_ANALYST_PROFILE_PAGE
} from '../../../constants/LinkPaths.constants';
import {
    TDS_DATE_FORMAT,
    EMAIL_DELIMITER,
    FORWARD_SLASH,
    FIRM_NAME_STRATEGY,
    FIRM_NAME_RESEARCH, FIRM_NAME_COWEN, FIRM_NAME_CA
} from '../../../constants/Misc.constants';
import { SEARCH_RESULTS_SHOW_TICKER_FOR_DOCUMENT_TYPES } from '../../../constants/SearchResults.constants';
import { buildSecureLink } from '../../../services/SecureLink.service';
import { isNotEmpty, isCowen, isCowenEnabled } from '../../../utils/valueUtils';
import CowenFlagContext from "../../../system/CowenFlagContext";
import {EQ_RESEARCH_CAN_MAPPING_ANALYST_PROFILE} from "../../../constants/Analyst.constants";


export default class SearchResultsItemComponent extends Component {

    static contextType = CowenFlagContext;
    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            cowenEnabled: false,
            profileAnalystId : this.props.item?.teamMemberPersonId,
            profileFirmName : this.props.exactFirm
        };

        if (props.item?.pdfLink) {
            props.item.pdfLink = buildSecureLink(props.item.pdfLink);
        }

        this.handleExpandClick = this.handleExpandClick.bind(this);
        this.handlePDFDownloadClick = this.handlePDFDownloadClick.bind(this);
    }

    componentDidMount() {
        isCowen().then(r => {
            this.setState({ cowenEnabled: r })
        }).catch(e => {
            console.log(e);
        });
        this.handleMappingToProfilePage()
    }
    handleMappingToProfilePage = () => {
        // eslint-disable-next-line array-callback-return
        EQ_RESEARCH_CAN_MAPPING_ANALYST_PROFILE.map(m=> {
            if ( m.bmIdSt === this.props.item?.teamMemberPersonId && m.bmIdCowen !== null && this.props.exactFirm === FIRM_NAME_CA ) {
                this.setState({profileAnalystId : m.bmIdCowen});
                this.setState({profileFirmName: FIRM_NAME_COWEN});
            }
        });
    }
    handleExpandClick = (event) => {
        this.setState({ expand: !this.state.expand });
    }
    handlePDFDownloadClick = (event) => {
        event.preventDefault();
        DownloadFileService.downloadPDF(event.target.dataset.firmid, event.target.dataset.productid, event.target.dataset.filename);
    }
    
    render() {
        const cowenFlag = this.context;
        return (
            <div className="tds-search-result-row">
                {/* Expand only available for FICM i.e. strategy */}
                {(!([FIRM_NAME_RESEARCH, FIRM_NAME_COWEN].includes(this.props.item.exactFirm)) && this.props.isFicm) ?
                    < div 
                    data-testid='expand-btn'
                    className="tds-search-results-column expand" onClick={this.handleExpandClick}>
                        {
                            this.state.expand &&
                            <span className="td-icon td-icon-collapse icon-small" title="Collapse"></span>
                        }
                        {
                            !this.state.expand &&
                            <span className="td-icon td-icon-expand icon-small" title="Expand"></span>
                        }
                    </div>
                    : ""
                }

                <div className="tds-search-results-column date">
                    <span className="tds-search-results-column-date-content">
                        <FormatedDateComponent
                            date={this.props.item.displayDateTime}
                            format={this.props.dateFormat || TDS_DATE_FORMAT}
                        />
                    </span>
                </div>
                <div className="tds-search-results-column title">
                    <React.Fragment>
                        {
                            SEARCH_RESULTS_SHOW_TICKER_FOR_DOCUMENT_TYPES.includes(this.props.item.bluematrixXmlAbbr) &&
                            <React.Fragment>
                                {
                                    this.props.item.primarySymbol &&
                                    <React.Fragment>
                                        ( {this.props.item.primarySymbol} )&nbsp;
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {this.props.item.title}
                    </React.Fragment>
                </div>
                <div className="tds-search-results-column analyst">
                    {/* Assuming if theres an analyst it will always be active. */}
                    {
                        this.props.item.firstName && this.props.item.firstName &&
                        <Link
                            to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH + this.state.profileFirmName +
                                FORWARD_SLASH + this.state.profileAnalystId}
                            title={this.props.item.firstName + ' ' + this.props.item.lastName}
                        >
                            {this.props.item.firstName} {this.props.item.lastName}
                        </Link>
                    }
                    {/* {
                        this.props.item.firstName && this.props.item.firstName  &&
                        //  !this.props.item.primaryAnalyst.active 
                         
                        <React.Fragment>
                            {this.props.item.firstName} {this.props.item.lastName}
                        </React.Fragment>
                    } */}
                </div>
                <div className="tds-search-results-column document-type">
                    {this.props.item.bluematrixXmlDescr}
                </div>
                <div className="tds-search-results-column action">
                    {
                        this.props.item.tenantId &&
                        this.props.item.parameterId &&
                        this.props.exactFirm === FIRM_NAME_STRATEGY &&
                        // && this.props.item.htmlLink && this.props.item.showHtml && this.props.item.isHTML5Supported === true &&
                        <Link
                            to={LINK_PUBLICATION_PAGE + this.props.exactFirm + FORWARD_SLASH + this.props.item.parameterId}
                            title={'Click to View'}
                        >
                            <span className="tds-view-icon">
                                <img className="tds-view-icon-svg"
                                    src="/assets/img/view-icon.svg"
                                    alt="View" />
                            </span>
                        </Link>
                    }

                    {isNotEmpty(this.props.item.pdfLink) && this.props.item.tenantId && this.props.item.productId && this.props.item.title &&
                        <a
                            href={buildSecureLink(this.props.item.pdfLink)}
                            title="View PDF"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span
                                className="td-icon td-icon-insights icon-small"
                                data-firmid={this.props.item.tenantId}
                                data-productid={this.props.item.productId}
                                data-filename={this.props.item.title}
                            ></span>
                        </a>
                    }
                    {
                        // this.props.item.htmlLink &&
                        this.props.exactFirm === FIRM_NAME_STRATEGY &&
                        <MailtoComponent
                            email={''}
                            titleText={'Share'}
                            icon={true}
                            subject={this.props.item.title}
                            body={"I'd like to share this article written by TD Securities with you." + EMAIL_DELIMITER + UtilsService.buildShareLink(this.props.exactFirm, this.props.item.parameterId)}
                        />
                    }
                    {
                       this.props.exactFirm !== FIRM_NAME_STRATEGY &&  this.props.item.pdfLink &&
                        [FIRM_NAME_RESEARCH, FIRM_NAME_COWEN].includes(this.props.exactFirm) &&
                        <MailtoComponent
                            email={''}
                            titleText={'Share'}
                            icon={true}
                            subject={this.props.item.title}
                            body={UtilsService.buildEQShareText(
                                this.props.item.pdfLink,
                                this.props.item.riskText,
                                this.props.item.recommendationText,
                                this.props.item.priceTargetText,
                                this.props.item.mktCapFdText,
                                this.props.item.displayDateTime,
                                this.props.item.abstractField,
                                isCowenEnabled(this.state.cowenEnabled, cowenFlag),
                                this.props.item.bluematrixXmlAbbr
                            )}
                        />
                    }
                </div>
                {
                    this.state.expand &&
                    <div className="tds-search-results-expanded">
                        {
                            // because other team members is sent as a string
                            this.props?.item?.otherTeamMembers &&   JSON.parse(this.props.item.otherTeamMembers).length > 0 &&
                            <span className="tds-search-results-expanded-description">
                                <span className="tds-search-results-expanded-description-title">Analyst: </span>
                                <span className="tds-search-results-expanded-description-content">                                    
                                        {JSON.parse(this.props.item.otherTeamMembers).map((analyst, index) => {                                            
                                     return (       <React.Fragment key={'tds-search-results-analyst' + index}>
                                               
                                                    <React.Fragment>
                                                        <Link
                                                            to={LINK_PATH_ANALYST_PROFILE_PAGE + FORWARD_SLASH + this.props.exactFirm +
                                                                FORWARD_SLASH + analyst.personId}
                                                            title={analyst.lastName + ' ' + analyst.firstName}
                                                        >
                                                            {analyst.lastName},&nbsp;{analyst.firstName}
                                                        </Link>
                                                        <span>&nbsp;&nbsp;</span>
                                                    </React.Fragment>
                                            </React.Fragment>
                                     )
                                            })}
                                </span>
                            </span>
                        }
                        {
                            this.props.item.bluematrixXmlDescr &&
                            <span className="tds-search-results-expanded-description document-type">
                                <span className="tds-search-results-expanded-description-title">Document Type: </span>
                                <span className="tds-search-results-expanded-description-content">
                                    {this.props.item.bluematrixXmlDescr}
                                </span>
                            </span>
                        }
                        {
                            !this.props.isFicm && this.props.item.industry &&
                            <span className="tds-search-results-expanded-description">
                                <span className="tds-search-results-expanded-description-title">Industry: </span>
                                <span className="tds-search-results-expanded-description-content">
                                    {this.props.item.industry}
                                </span>
                            </span>
                        }
                        {
                            !this.props.isFicm && this.props.item.company &&
                            <span className="tds-search-results-expanded-description">
                                <span className="tds-search-results-expanded-description-title">Companies: </span>
                                <span className="tds-search-results-expanded-description-content">
                                    {this.props.item.company}
                                </span>
                            </span>
                        }
                        {
                            this.props.item.summary &&
                            <span className="tds-search-results-expanded-description-summary">
                                {executeInnerHtml(this.props.item.summary)}
                            </span>
                        }
                    </div>
                }
            </div >
        )
    }
}