export const REST_BASE_PATH = "/api/v1";

export const REST_BASE_PATH_V2 = "/api/v2";

export const REST_PERSON_SEARCH_PATH = REST_BASE_PATH + "/analyst/search1";

export const REST_ANALYSTS_PATH = REST_BASE_PATH + "/analyst/search";

export const REST_ANALYST_PROFILE_PATH = REST_BASE_PATH + "/analyst/";

export const REST_ANALYST_TEAMS_PATH = REST_BASE_PATH + "/analyst/group/teams";

export const REST_SEARCH_DROPDOWN_DATA_PATH = REST_BASE_PATH + "/content/search/static/values";

export const REST_SEARCH_DROPDOWN_DATA_PATH_V2 = REST_BASE_PATH_V2 + "/content/search/static/values";

export const REST_SEARCH_PATH = REST_BASE_PATH + "/content/search";

export const REST_SEARCH_PATH_V2 = REST_BASE_PATH_V2 + "/content/search";

export const REST_HOMEPAGE_PATH = REST_BASE_PATH + "/pages/home";

export const REST_EQ_OVERVIEW_PATH_V2 = REST_BASE_PATH_V2 + "/pages/eq-overview";

export const REST_EQUITIES_HOMEPAGE_PATH = REST_BASE_PATH + "/pages/home?eqOnly=true";

export const REST_HOMEPAGE_TAILORED_FOR_YOU_PATH_V2 = REST_BASE_PATH_V2 + "/pages/eq-overview/tfl";

export const REST_HOMEPAGE_TAILORED_FOR_YOU_PATH = REST_BASE_PATH + "/pages/home/tfl";

export const REST_CONTENT_PATH = REST_BASE_PATH + "/content";

export const REST_PDF_PATH = REST_BASE_PATH + "/content/pdf/";

export const REST_PRODUCT_PAGE_BASE_PATH = REST_BASE_PATH + "/pages/product/";

export const REST_HOMEPAGE_MOST_RECENT_PATH = REST_BASE_PATH + "/content/recent";

export const REST_HOMEPAGE_MOST_RECENT_PATH_V2 = REST_BASE_PATH_V2 + "/pages/eq-overview/recent";

export const REST_HOMEPAGE_IN_DEPTHS_PATH_V2 = REST_BASE_PATH_V2 + "/content/indepth/reports";

export const REST_HOMEPAGE_MOST_POPULAR_PATH = REST_BASE_PATH + "/content/popular";

export const REST_HOMEPAGE_MOST_POPULAR_REGIONS_PATH = REST_BASE_PATH + "/content/region/popular/reads";

export const REST_TRADE_IDEAS_PRODUCT_PATH = REST_BASE_PATH + "/tradeideas/products";

export const REST_TRADE_IDEAS_REGIONS_PATH = REST_BASE_PATH + "/tradeideas/regions";

export const REST_REAL_TIME_COVERAGE_PATH = REST_BASE_PATH + "/content/realtimecov";

export const REST_REAL_TIME_COVERAGE_REGIONS_PATH = REST_BASE_PATH + '/content/realtimecov/regions';

export const REST_REAL_TIME_COVERAGE_PRODUCTS_PATH = REST_BASE_PATH + '/content/realtimecov/products';

export const REST_SIDEBAR_UPCOMING_EVENT_PATH = REST_BASE_PATH + "/events/upcoming/content";

export const REST_SIDEBAR_TRENDING_NOW_PATH = REST_BASE_PATH + "/content/trending";

export const REST_SIDEBAR_RECENTLY_VIEWED_PATH = REST_BASE_PATH + "/content/user/viewed";

export const REST_REGION_PAGE_CARDS_PATH = REST_BASE_PATH + '/pages/region/home/';

export const REST_REGION_PAGE_TRADE_IDEAS_PATH = REST_BASE_PATH + "/tradeideas/regions/";

export const REST_REGION_PAGE_FORECAST_SNAPSHOT_PATH = REST_BASE_PATH + "/content/forecast/";

export const REST_THEMATIC_PATH = REST_BASE_PATH + "/thematic/content/";

export const REST_DATAFLOW_PATH = REST_BASE_PATH + "/dataflow/content/";

export const REST_COVERAGE_PAGE_COMPANIES = REST_BASE_PATH + "/equity/company/";

export const REST_COVERAGE_PAGE_ACTION_LIST = REST_BASE_PATH + "/equity/action-list";

export const REST_COVERAGE_PAGE_ADVANCED_FILTER = REST_BASE_PATH + "/equity/advanced-filter";

export const REST_RESEARCH_PAGE = REST_BASE_PATH + "/equity/research/static/values";

export const REST_RESEARCH_PAGE_V2 = REST_BASE_PATH_V2 + "/equity/research/static/values";

export const REST_FIRST_TAKE_PUBLICATIONS_PATH = REST_BASE_PATH + "/first-take-publications";

export const REST_FIRST_TAKE_PUBLICATIONS_PATH_V2 = REST_BASE_PATH_V2 + "/first-take-publications";

export const REST_RESEARCH_LATEST_BY_TYPES = REST_BASE_PATH + "/equity/research/latest-content-bytypes";

export const REST_RESEARCH_LATEST_BY_TYPES_V2 = REST_BASE_PATH_V2 + "/equity/research/latest-content";

export const REST_COMPANY_DETAILS_PATH = REST_BASE_PATH + "/equity/company-details/";

export const REST_CALENDAR_PATH = REST_BASE_PATH + "/calendar/research/";

export const REST_EQUITIES_COMPANIES_DOWNLOAD = REST_BASE_PATH + "/equity/companies-list/xls";

export const REST_COMPANY_NOTES_PATH = REST_BASE_PATH + "/equity/company-content/";

export const REST_COMPANY_NOTES_PATH_V2 = REST_BASE_PATH_V2 + "/equity/company-content/";

export const REST_PUBLIC_DISCLOSURES_EQUITY_PATH = REST_BASE_PATH + "/public-disclosures/equities";

export const REST_INDEX_MARKET_STRUCTURES_PATH = REST_BASE_PATH + "/equity/ims";

export const REST_CONFERENCE_CALLS_OTHER_MEDIA_PATH = REST_BASE_PATH_V2 + "/content/conference-calls";

export const REST_EXCHANGE_TRADED_FUNDS_SUMMARY_PATH = REST_BASE_PATH + "/content/etf/research/summary";

export const REST_EXCHANGE_TRADED_FUNDS_DETAILS_PATH = REST_BASE_PATH + "/content/etf/research/details";

export const REST_ASSET_SECURITIZATION_PAGE = REST_BASE_PATH + "/content/asg";

export const REST_INVESTMENT_GRADE_PAGE = REST_BASE_PATH + "/content/ig";

export const REST_LATEST_ACTION_LIST_FULL_REPORT_PATH = REST_BASE_PATH + "/equity/research/latest-action-list-content";

export const REST_GA_FLAG_PATH = REST_BASE_PATH + "/vault/ga/alpha/flag";

export const REST_GA_TRACKING_ID_PATH = REST_BASE_PATH + "/vault/ga/alpha/track-id";

export const REST_ONE_TRUST_ID_PATH = REST_BASE_PATH + "/vault/ga/alpha/one-trust-id";

export const REST_FEATURES_IS_ENABLED = REST_BASE_PATH_V2 + "/features/is-enabled/";

export const REST_V3_API_URL = REST_BASE_PATH_V2 + "/features/v3-api-url";

export const REST_STOCKS_STATS_TIME_SERIES_PATH = REST_BASE_PATH_V2 + "/equity/issuers/";

export const REST_AUTO_APPROVE_SEARCH_PATH = REST_BASE_PATH + "/auto-approve/search";

export const REST_LOGOUT_PATH = "/logout";

export const REST_MONEY_TALK_PAGE = REST_BASE_PATH_V2 + '/money-talks';

export const REST_MONEY_TALK_FLAG_PATH = REST_BASE_PATH + "/vault/money-talk/alpha/flag";