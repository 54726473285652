import { handleCanada } from "./handleCAV3";
import { handleFicm } from "./handleFICMV3";
import { handleUS } from "./handleUSV3";
import { handleWRG } from "./handleWRGV3";
import { getProjection } from "./queryBuildUtils";

export function buildQuery({
  projection,
  size = 10,
  filters = {},
  page,
  orderBy,
}) {
  let query;

  if (getProjection(projection) === "ficmContent") {
    query = handleFicm(filters, size, projection, orderBy, page);
  } else if (getProjection(projection) === "eqWrgContent") {
    query = handleWRG(filters, size, projection, orderBy, page);
  } else if (getProjection(projection) === "eqUsContent") {
    query = handleUS(filters, size, projection, orderBy, page);
  } else if (getProjection(projection) === "eqCaContent") {
    query = handleCanada(filters, size, projection, orderBy, page);
  }

  return query;
}
