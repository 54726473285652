export const SITE_VERSION = '/alpha';

export const ROUTE_PATH_404_PAGE = SITE_VERSION + '/404';

export const ROUTE_CALENDAER_PAGE = SITE_VERSION + '/calendar';

export const ROUTE_PATH_ANALYST_PROFILE_PAGE = SITE_VERSION + '/analysts/profile/:firmName/:profileId';

export const ROUTE_PATH_ANALYSTS_PAGE = SITE_VERSION + '/analysts';

export const ROUTE_PATH_ANALYST_TEAMS_PAGE = SITE_VERSION + '/analyst-teams';

export const ROUTE_PATH_ASSET_SECURITIZATION = SITE_VERSION + '/desk-research/asset-securitization';

export const ROUTE_PATH_EXCHANGE_TRADED_FUNDS = SITE_VERSION + '/desk-research/exchange-traded-funds';

export const ROUTE_PATH_EXCHANGE_TRADED_FUNDS_DETAILS = SITE_VERSION + '/desk-research/exchange-traded-funds/details/:type';

export const ROUTE_PATH_EQUITIES_COMPANY_DETAILS_PAGE = SITE_VERSION + '/equities/company/details/:issuerId';

export const ROUTE_PATH_EQUITIES_COVERAGE_PAGE = SITE_VERSION + '/equities/coverage';

export const ROUTE_PATH_EQUITIES_ADVANCED_FILTER = SITE_VERSION + '/equities/advanced-filter';

export const ROUTE_PATH_EQUITIES_RESEARCH_PAGE = SITE_VERSION + '/equities/research';

export const ROUTE_PATH_EQUITIES_OVERVIEW_PAGE = SITE_VERSION + '/equities/equities-overview';

export const ROUTE_PATH_ANALYTICS_TRADE_GLOBAL_STRATEGY_PAGE = SITE_VERSION + '/analytics-trade/global-strategy';

export const ROUTE_CREDIT_HIGH_YIELD_PAGE = SITE_VERSION + '/credit/high-yield';

export const ROUTE_CREDIT_INVESTMENT_GRADE_PAGE = SITE_VERSION + '/credit/investment-grade';

export const ROUTE_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE = SITE_VERSION + '/equities/index-market-structures';

export const ROUTE_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA_PAGE = SITE_VERSION + '/equities/conference-calls';

export const ROUTE_PUBLICATION_PAGE = SITE_VERSION + '/content/:firmId/:contentId';

export const ROUTE_COMMODITIES_PAGE = SITE_VERSION + '/commodities';

export const ROUTE_EVENTS_PAGE = SITE_VERSION + '/events';

export const ROUTE_EMERGING_MARKETS_PAGE = SITE_VERSION + '/emerging-markets';

export const ROUTE_REGIONS_PAGE_UNITED_STATES = SITE_VERSION + '/regions/us';

export const ROUTE_REGIONS_PAGE_CANADA = SITE_VERSION + '/regions/canada';

export const ROUTE_REGIONS_PAGE_ASIA_PACIFIC = SITE_VERSION + '/regions/asia-pacific';

export const ROUTE_REGIONS_PAGE_JAPAN = SITE_VERSION + '/regions/japan';

export const ROUTE_REGIONS_PAGE_NEW_ZEALAND = SITE_VERSION + '/regions/new-zealand';

export const ROUTE_REGIONS_PAGE_AUSTRALIA = SITE_VERSION + '/regions/australia';

export const ROUTE_REGIONS_PAGE_EUROPE = SITE_VERSION + '/regions/europe';

export const ROUTE_REGIONS_PAGE_EUROZONE = SITE_VERSION + '/regions/eurozone';

export const ROUTE_REGIONS_PAGE_UNITED_KINGDOM = SITE_VERSION + '/regions/united-kingdom';

export const ROUTE_REGIONS_PAGE_SCANDIES = SITE_VERSION + '/regions/scandies';

export const ROUTE_REGIONS_PAGE_EMEA = SITE_VERSION + '/regions/emea';

export const ROUTE_REGIONS_PAGE_ASIA = SITE_VERSION + '/regions/asia';

export const ROUTE_REGIONS_PAGE_CHINA = SITE_VERSION + '/regions/china';

export const ROUTE_REGIONS_PAGE_ASIA_EX_CHINA = SITE_VERSION + '/regions/asia-ex-china';

export const ROUTE_REGIONS_PAGE_LATIN_AMERICA = SITE_VERSION + '/regions/latin-america';

export const ROUTE_FX_PAGE = SITE_VERSION + '/fx';

export const ROUTE_LOGOUT =  SITE_VERSION + '/logout';

export const ROUTE_MACRO_PAGE = SITE_VERSION + '/macro';

export const ROUTE_MVI = SITE_VERSION + '/mvi';

export const ROUTE_RATES_PAGE = SITE_VERSION + '/rates';

export const ROUTE_SEARCH_PAGE = SITE_VERSION + '/search';

export const ROUTE_HOME_PAGE = SITE_VERSION + '/home';

export const ROUTE_LANDING_PAGE = SITE_VERSION + '/landing';

export const ROUTE_LANDING_SUB_PAGE = SITE_VERSION + '/*';

export const ROUTE_DISSEMINATION_POLICY_PAGE = SITE_VERSION + '/dissemination-policy'; 

export const ROUTE_FICC_RESEARCH_CREDIT_STRATEGY_PAGE = SITE_VERSION + '/ficc-research-credit-strategy';

export const ROUTE_SALE_LITERATURE_PAGE = SITE_VERSION + '/sales-literature';

export const ROUTE_HOME = SITE_VERSION ;

export const ROUTE_ROOT = '/';

export const ROUTE_ACCESS_DENIED = SITE_VERSION + '/accessdenied';

export const LINK_TDSECURITIES_PRIVACY = "https://www.tdsecurities.com/ca/en/privacy-security";

export const ROUTE_PATH_EQUITIES_CA_OVERVIEW_PAGE = SITE_VERSION + '/equities/equities-research-ca';

export const ROUTE_PATH_EQUITIES_CA_RESEARCH_PAGE = SITE_VERSION + '/equities/ca/research';

export const ROUTE_PATH_EQUITIES_US_OVERVIEW_PAGE = SITE_VERSION + '/equities/equities-research-us';

export const ROUTE_PATH_EQUITIES_US_RESEARCH_PAGE = SITE_VERSION + '/equities/us/research';

export const ROUTE_PATH_WASHINGTON_RESEARCH_GROUP_OVERVIEW_PAGE = SITE_VERSION + '/equities/washington-research-group';

export const ROUTE_PATH_WASHINGTON_RESEARCH_GROUP_PAGE = SITE_VERSION + '/equities/wrg/research';

export const ROUTE_V2_SEARCH_PAGE = SITE_VERSION + '/v2/search';

export const ROUTE_ONE_PORTAL_REGISTRATION_REQUEST = 'https://oneportal.tdsecurities.com/registration-request';

export const ROUTE_ONE_PORTAL_LEARN_MORE = SITE_VERSION + '/faq-literature'

export const ROUTE_PATH_MONEY_TALK_PAGE = SITE_VERSION + '/money-talk';

export const ROUTE_DESK_RESEARCH_CREDIT_INVESTMENT_GRADE_PAGE = SITE_VERSION + '/desk-research/investment-grade';
