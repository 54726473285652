import { getProjection, getSort } from "./queryBuildUtils";

export const handleFicm = (filters, size, projection, orderBy, page) => {
  const filterClauses = [];

  let query = `/search?size=${size}`;

  if (filters.keyword && filters.keyword.length > 0) {
    const keywords = filters.keyword
      .map((kw) =>{
        let keyword = kw[0];
        let sanitized = keyword.replace(/'/g, "\\'");
        return  `(keyPoints~~'${sanitized}' or title~~'${sanitized}')`
      })
      .join(" or ");

      filterClauses.push(`(${encodeURIComponent(keywords)})`);
  }

  if (filters.analyst && filters.analyst.length > 0) {
    const analyst = filters.analyst
      .map(
        (analyst) =>
          `(teamMemberPersonId in [${analyst}] or otherTeamMembers~~'"personId":${analyst},')`
      )
      .join(" or ");

    filterClauses.push(`(${analyst})`);
  }

  if (filters.product && filters.product.length > 0) {
    const productFilter = filters.product
      .map(
        (product) => {
           const safeProduct = encodeURIComponent(product);
          return `(tagProducts~',${safeProduct},' or tagProducts~'*,${safeProduct}' or tagProducts~'${safeProduct},*' or tagProducts:'${safeProduct}')`
        }
      )
      .join(" or ");

    filterClauses.push(`(${productFilter})`);
  }
  if (filters.region && filters.region.length > 0) {
    const regionFilter = filters.region
      .map(
        (region) => {
           const safeRegion = encodeURIComponent(region);
         return `( tagRegions~',${safeRegion},' or  tagRegions~'*,${safeRegion}' or tagRegions~'${safeRegion},*' or tagRegions:'${safeRegion}' )`
        }
      )
      .join(" or ");

    filterClauses.push(`(${regionFilter})`);
  }

  if (filters.documentType && filters.documentType.length > 0) {
    const normalizeDocType = filters.documentType.some((item) =>
      Array.isArray(item)
    )
      ? filters.documentType.flat()
      : filters.documentType;
    const doctypeGroups = normalizeDocType.reduce((acc, doc) => {
      const { firmId, bmXmlId } = doc;

      if (!acc[firmId]) {
        acc[firmId] = [];
      }
      acc[firmId] = [...acc[firmId], bmXmlId];
      return acc;
    }, {});
    const docFilters = Object.entries(doctypeGroups)
      .map(([firmId, bmXmlIds]) => {
        const uniqueXmlIds = [...new Set([].concat(...bmXmlIds))];
        return `(tenantId:${firmId} and bluematrixXmlID IN [${uniqueXmlIds.join(
          ","
        )}])`;
      })
      .join(" OR ");
    filterClauses.push(`(${docFilters})`);
  }

  if (filters.dateRange) {
    const formatDate = (date) =>
      new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(new Date(date));
    const start =
      filters.dateRange.start && formatDate(filters.dateRange.start);
    const end = filters.dateRange.end && formatDate(filters.dateRange.end);
    if (start && end) {
      filterClauses.push(
        `((displayDateTime >= '${start} 00:00:00' and displayDateTime <= '${end} 23:59:59'))`
      );
    } else if (start) {
      filterClauses.push(`((displayDateTime >= '${start} 00:00:00'))`);
    } else if (end) {
      filterClauses.push(`((displayDateTime <= '${end} 23:59:59'))`);
    }
  }
  if (filterClauses.length === 1) {
    query += `&filter=${filterClauses[0]}`;
  } else if (filterClauses.length > 1) {
    query += `&filter=${filterClauses.join(" and ")}`;
  }
  query += `&projection=${getProjection(projection)}${getSort(
    orderBy
  )}&sort=displayDateTime,desc&page=${page - 1}`;
  return query;
};
