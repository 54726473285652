import {COVERAGE_PAGE_TABS, URL_PARAM_TAB} from "./CoveragePage.constants";
import {AND, EQUALS, QUESTION_MARK, TEAM, TEAM_TYPE} from "./Misc.constants";
import {RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE, RESEARCH_PAGE_TAB} from "./ResearchPage.constants";

export const LINK_TDSECURITIES = 'https://www.tdsecurities.com/ca/en/home-page';

export const LINK_TDSECURITIES_EQUITY = 'https://portal.tdsecurities.com/alpha/landing';

export const LINK_SITE_VERSION = '/alpha';

export const LINK_PATH_404_PAGE = LINK_SITE_VERSION + '/404';

export const LINK_PATH_ANALYST_PROFILE_PAGE = LINK_SITE_VERSION + '/analysts/profile';

export const LINK_PATH_ANALYSTS_PAGE = LINK_SITE_VERSION + '/analysts';

export const LINK_PATH_ANALYST_TEAMS_PAGE = LINK_SITE_VERSION + '/analyst-teams';

export const LINK_PUBLICATION_PAGE = LINK_SITE_VERSION + '/content/';

export const LINK_COMMODITIES_PAGE = LINK_SITE_VERSION + '/commodities';

export const LINK_EVENTS_PAGE = LINK_SITE_VERSION + '/events';

export const LINK_EQUITIES_COMPANY_DETAILS_PAGE = LINK_SITE_VERSION + '/equities/company/details';

export const LINK_EQUITIES_COVERAGE_PAGE = LINK_SITE_VERSION + '/equities/coverage';

export const LINK_EQUITIES_COVERAGE_COMPANY_PAGE = LINK_EQUITIES_COVERAGE_PAGE + QUESTION_MARK + URL_PARAM_TAB + EQUALS + COVERAGE_PAGE_TABS.COMPANIES;

export const LINK_EQUITIES_COVERAGE_ACTION_LIST_PAGE = LINK_EQUITIES_COVERAGE_PAGE + QUESTION_MARK + URL_PARAM_TAB + EQUALS + COVERAGE_PAGE_TABS.ACTION_LIST;

export const LINK_EQUITIES_COVERAGE_ADVANCED_FILTER_PAGE = LINK_SITE_VERSION + '/equities/advanced-filter';

export const LINK_EQUITIES_RESEARCH_PAGE = LINK_SITE_VERSION + '/equities/research';

export const LINK_EQUITIES_RESEARCH_ANALYST_PAGE = LINK_EQUITIES_RESEARCH_PAGE + QUESTION_MARK + RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE + EQUALS + RESEARCH_PAGE_TAB.ANALYST;

export const LINK_EQUITIES_RESEARCH_COMPANY_PAGE = LINK_EQUITIES_RESEARCH_PAGE + QUESTION_MARK + RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE + EQUALS + RESEARCH_PAGE_TAB.COMPANY;

export const LINK_EQUITIES_RESEARCH_INDUSTRY_PAGE = LINK_EQUITIES_RESEARCH_PAGE + QUESTION_MARK + RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE + EQUALS + RESEARCH_PAGE_TAB.INDUSTRY;

export const LINK_EQUITIES_OVERVIEW_PAGE = LINK_SITE_VERSION + '/equities/equities-overview';

export const LINK_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE = LINK_SITE_VERSION + '/equities/index-market-structures';

export const LINK_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA_PAGE = LINK_SITE_VERSION + '/equities/conference-calls';

export const LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_PAGE = LINK_SITE_VERSION + '/desk-research/exchange-traded-funds';

export const LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_DETAILS_PAGE = LINK_SITE_VERSION + '/desk-research/exchange-traded-funds/details/';

export const LINK_REGIONS_BASE_PAGE = LINK_SITE_VERSION + '/regions/';

export const LINK_REGIONS_US_PAGE = LINK_SITE_VERSION + '/regions/us';

export const LINK_REGIONS_CANADA_PAGE = LINK_SITE_VERSION + '/regions/canada';

export const LINK_REGIONS_ASIA_PACIFIC_PAGE = LINK_SITE_VERSION + '/regions/asia-pacific';

export const LINK_REGIONS_EUROPE_PAGE = LINK_SITE_VERSION + '/regions/europe';

export const LINK_REGIONS_EMEA_PAGE = LINK_SITE_VERSION + '/regions/emea';

export const LINK_REGIONS_ASIA_PAGE = LINK_SITE_VERSION + '/regions/asia';

export const LINK_REGIONS_LATIN_AMERICA_PAGE = LINK_SITE_VERSION + '/regions/latin-america';

export const LINK_EMERGING_MARKETS_PAGE = LINK_SITE_VERSION + '/emerging-markets';

export const LINK_DESK_RESEARCH_ASSET_SECURITIZATION = LINK_SITE_VERSION + '/desk-research/asset-securitization';

export const LINK_CREDIT_HIGH_YIELD = LINK_SITE_VERSION + '/credit/high-yield';

export const LINK_CREDIT_INVESTMENT_GRADE = LINK_SITE_VERSION + '/credit/investment-grade';

export const ACCESS_DENIED = LINK_SITE_VERSION + '/accessdenied';

export const LINK_ANALYTICS_TRADE_GLOBAL_STRATEGY = LINK_SITE_VERSION + '/analytics-trade/global-strategy';

export const LINK_FX_PAGE = LINK_SITE_VERSION + '/fx';

export const LINK_LOGOUT =  LINK_SITE_VERSION + '/logout';

export const LINK_MACRO_PAGE = LINK_SITE_VERSION + '/macro';

export const LINK_MVI = LINK_SITE_VERSION + '/mvi';

export const LINK_RATES_PAGE = LINK_SITE_VERSION + '/rates';

export const LINK_SEARCH_PAGE = LINK_SITE_VERSION + '/search';

export const LINK_HOME = LINK_SITE_VERSION;

export const LINK_LANDING_PAGE = LINK_SITE_VERSION + '/landing';

export const LINK_HOME_PAGE = LINK_SITE_VERSION + '/home';

export const LINK_CALENDER = LINK_SITE_VERSION + '/calendar';

export const LINK_PATH_SALES_LITERATURE_PAGE = LINK_SITE_VERSION + '/sales-literature';

export const LINK_PATH_DISEMMINATION_POLICY_PAGE = LINK_SITE_VERSION + '/dissemination-policy';

export const LINK_PATH_FICC_RESEARCH_CREDIT_STRATEGY_PAGE = LINK_SITE_VERSION + '/ficc-research-credit-strategy';

export const LINK_TDSECURITIES_ALPHA = 'https://portal.tdsecurities.com/alpha';

export const LINK_EQUITIES_CA_OVERVIEW_PAGE = LINK_SITE_VERSION + '/equities/equities-research-ca';

export const LINK_EQUITIES_CA_RESEARCH_ANALYST_PAGE = LINK_SITE_VERSION + "/equities/ca/research?"+RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE+EQUALS+RESEARCH_PAGE_TAB.ANALYST+AND+TEAM_TYPE+EQUALS+TEAM.CA;

export const LINK_EQUITIES_CA_RESEARCH_COMPANY_PAGE = LINK_SITE_VERSION + "/equities/ca/research?"+RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE+EQUALS+RESEARCH_PAGE_TAB.COMPANY+AND+TEAM_TYPE+EQUALS+TEAM.CA;

export const LINK_EQUITIES_CA_RESEARCH_INDUSTRY_PAGE = LINK_SITE_VERSION + "/equities/ca/research?"+RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE+EQUALS+RESEARCH_PAGE_TAB.INDUSTRY+AND+TEAM_TYPE+EQUALS+TEAM.CA;

export const LINK_EQUITIES_US_OVERVIEW_PAGE = LINK_SITE_VERSION + '/equities/equities-research-us';

export const LINK_EQUITIES_US_RESEARCH_ANALYST_PAGE = LINK_SITE_VERSION + "/equities/us/research?"+RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE+EQUALS+RESEARCH_PAGE_TAB.ANALYST+AND+TEAM_TYPE+EQUALS+TEAM.US;

export const LINK_EQUITIES_US_RESEARCH_COMPANY_PAGE = LINK_SITE_VERSION + "/equities/us/research?"+RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE+EQUALS+RESEARCH_PAGE_TAB.COMPANY+AND+TEAM_TYPE+EQUALS+TEAM.US;

export const LINK_EQUITIES_US_RESEARCH_INDUSTRY_PAGE = LINK_SITE_VERSION + "/equities/us/research?"+RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE+EQUALS+RESEARCH_PAGE_TAB.INDUSTRY+AND+TEAM_TYPE+EQUALS+TEAM.US;

export const LINK_EQUITIES_WRG_OVERVIEW_PAGE = LINK_SITE_VERSION + '/equities/washington-research-group';

export const LINK_EQUITIES_WRG_RESEARCH_ANALYST_PAGE = LINK_SITE_VERSION + "/equities/wrg/research?"+RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE+EQUALS+RESEARCH_PAGE_TAB.ANALYST+AND+TEAM_TYPE+EQUALS+TEAM.WRG;

export const LINK_EQUITIES_WRG_RESEARCH_INDUSTRY_PAGE = LINK_SITE_VERSION + "/equities/wrg/research?"+RESEARCH_PAGE_QUERY_PARAMS_RESEARCH_TYPE+EQUALS+RESEARCH_PAGE_TAB.INDUSTRY+AND+TEAM_TYPE+EQUALS+TEAM.WRG;

export const LINK_MONEY_TALK_INTERVIEWS_PAGE = LINK_SITE_VERSION + '/money-talk';


export const LINK_DESK_RESEARCH_CREDIT_INVESTMENT_GRADE = LINK_SITE_VERSION + '/desk-research/investment-grade';