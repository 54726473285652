import { getProjection, getSort } from "./queryBuildUtils";

export const handleWRG = (filters, size, projection, orderBy, page) => {
  let query = `/search?projection=${getProjection(projection)}&size=${size}`;

  let filterClauses = [];

  if (filters.keyword && filters.keyword.length > 0) {
    const keywords = filters.keyword
      .map((kw) => {
        let keyword = kw[0];
        let sanitized = keyword.replace(/'/g, "\\'");
        return `(title~~'${sanitized}' OR relatedCompanies~~'${sanitized}' OR issuer.name~~'${sanitized}' OR issuer.symbol~~'${sanitized}')`;
      })
      .join(" or ");
      filterClauses.push(`(${encodeURIComponent(keywords)})`);
  }
  if (filters.analyst && filters.analyst.length > 0) {
    const industryIds = filters.analyst.join(",");
    filterClauses.push(`((teamMembers.personId in [${industryIds}]))`);
  }

  if (filters.industry && filters.industry.length > 0) {
    const industryIds = filters.industry.join(",");
    filterClauses.push(`((relatedIndustries.industryId in [${industryIds}]))`);
  }

  if (filters.documentType && filters.documentType.length > 0) {
    const normalizeDocType = filters.documentType.some((item) =>
      Array.isArray(item)
    )
      ? filters.documentType.flat()
      : filters.documentType;
    const doctypeGroups = normalizeDocType.reduce((acc, doc) => {
      
      const { firmId, bmXmlId } = doc;

      if (!acc[firmId]) {
        acc[firmId] = [];
      }
      acc[firmId] = [...acc[firmId], bmXmlId];
      return acc;
    }, {});
    const docFilters = Object.entries(doctypeGroups)
      .map(([firmId, bmXmlIds]) => {
        const uniqueXmlIds = [...new Set([].concat(...bmXmlIds))];

        return `(tenantId:${firmId} and bluematrixXmlID IN [${uniqueXmlIds.join(
          ","
        )}])`;
      })
      .join(" OR ");

    filterClauses.push(`(${docFilters})`);
  }

  if (filters.dateRange) {
    const formatDate = (date) =>
      new Intl.DateTimeFormat("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(new Date(date));
    const start =
      filters.dateRange.start && formatDate(filters.dateRange.start);
    const end = filters.dateRange.end && formatDate(filters.dateRange.end);
    if (start && end) {
      filterClauses.push(
        `((displayDateTime >= '${start} 00:00:00' and displayDateTime <= '${end} 23:59:59'))`
      );
    } else if (start) {
      filterClauses.push(`((displayDateTime >= '${start} 00:00:00'))`);
    } else if (end) {
      filterClauses.push(`((displayDateTime <= '${end} 23:59:59'))`);
    }
  }
  if (filterClauses.length === 1) {
    query += `&filter=${filterClauses[0]}`;
  } else if (filterClauses.length > 1) {
    query += `&filter=${filterClauses.join(" and ")}`;
  }

  query += `${getSort(orderBy)}&sort=displayDateTime,desc&page=${page - 1}`;

  return query;
};