
export const EQUITY_RESEARCH_CANADA_MAPPING_ANALYST = [
    { bmIdSt: 1, bmIdCowen: null },
    { bmIdSt: 2, bmIdCowen: 924 },
    { bmIdSt: 3, bmIdCowen: 925 },
    { bmIdSt: 4, bmIdCowen: 886 },
    { bmIdSt: 5, bmIdCowen: null },
    { bmIdSt: 6, bmIdCowen: 926 },
    { bmIdSt: 7, bmIdCowen: null },
    { bmIdSt: 8, bmIdCowen: 927 },
    { bmIdSt: 9, bmIdCowen: null },
    { bmIdSt: 10, bmIdCowen: null },
    { bmIdSt: 11, bmIdCowen: 919 },
    { bmIdSt: 12, bmIdCowen: 928 },
    { bmIdSt: 13, bmIdCowen: null },
    { bmIdSt: 14, bmIdCowen: 929 },
    { bmIdSt: 15, bmIdCowen: null },
    { bmIdSt: 16, bmIdCowen: null },
    { bmIdSt: 17, bmIdCowen: 930 },
    { bmIdSt: 18, bmIdCowen: 931 },
    { bmIdSt: 19, bmIdCowen: 932 },
    { bmIdSt: 20, bmIdCowen: 933 },
    { bmIdSt: 21, bmIdCowen: 934 },
    { bmIdSt: 22, bmIdCowen: 935 },
    { bmIdSt: 23, bmIdCowen: null },
    { bmIdSt: 24, bmIdCowen: null },
    { bmIdSt: 25, bmIdCowen: 936 },
    { bmIdSt: 26, bmIdCowen: null },
    { bmIdSt: 27, bmIdCowen: 937 },
    { bmIdSt: 28, bmIdCowen: 91 },
    { bmIdSt: 29, bmIdCowen: null },
    { bmIdSt: 30, bmIdCowen: 938 },
    { bmIdSt: 31, bmIdCowen: null },
    { bmIdSt: 32, bmIdCowen: 939 },
    { bmIdSt: 33, bmIdCowen: null },
    { bmIdSt: 34, bmIdCowen: null },
    { bmIdSt: 35, bmIdCowen: 940 },
    { bmIdSt: 36, bmIdCowen: null },
    { bmIdSt: 37, bmIdCowen: null },
    { bmIdSt: 38, bmIdCowen: 941 },
    { bmIdSt: 39, bmIdCowen: null },
    { bmIdSt: 40, bmIdCowen: 942 },
    { bmIdSt: 41, bmIdCowen: null },
    { bmIdSt: 42, bmIdCowen: null },
    { bmIdSt: 43, bmIdCowen: null },
    { bmIdSt: 44, bmIdCowen: null },
    { bmIdSt: 45, bmIdCowen: 943 },
    { bmIdSt: 46, bmIdCowen: null },
    { bmIdSt: 47, bmIdCowen: 944 },
    { bmIdSt: 48, bmIdCowen: 945 },
    { bmIdSt: 49, bmIdCowen: null },
    { bmIdSt: 50, bmIdCowen: null },
    { bmIdSt: 51, bmIdCowen: null },
    { bmIdSt: 52, bmIdCowen: null },
    { bmIdSt: 53, bmIdCowen: null },
    { bmIdSt: 54, bmIdCowen: null },
    { bmIdSt: 55, bmIdCowen: null },
    { bmIdSt: 56, bmIdCowen: null },
    { bmIdSt: 57, bmIdCowen: null },
    { bmIdSt: 58, bmIdCowen: null },
    { bmIdSt: 59, bmIdCowen: null },
    { bmIdSt: 60, bmIdCowen: null },
    { bmIdSt: 61, bmIdCowen: null },
    { bmIdSt: 62, bmIdCowen: null },
    { bmIdSt: 63, bmIdCowen: 946 },
    { bmIdSt: 64, bmIdCowen: null },
    { bmIdSt: 65, bmIdCowen: null },
    { bmIdSt: 66, bmIdCowen: 947 },
    { bmIdSt: 67, bmIdCowen: 948 },
    { bmIdSt: 68, bmIdCowen: 949 },
    { bmIdSt: 69, bmIdCowen: null },
    { bmIdSt: 70, bmIdCowen: null },
    { bmIdSt: 71, bmIdCowen: null },
    { bmIdSt: 72, bmIdCowen: null },
    { bmIdSt: 73, bmIdCowen: null },
    { bmIdSt: 74, bmIdCowen: 950 },
    { bmIdSt: 75, bmIdCowen: 951 },
    { bmIdSt: 76, bmIdCowen: 952 },
    { bmIdSt: 77, bmIdCowen: null },
    { bmIdSt: 78, bmIdCowen: null },
    { bmIdSt: 79, bmIdCowen: null },
    { bmIdSt: 80, bmIdCowen: null },
    { bmIdSt: 81, bmIdCowen: 953 },
    { bmIdSt: 82, bmIdCowen: null },
    { bmIdSt: 83, bmIdCowen: null },
    { bmIdSt: 84, bmIdCowen: null },
    { bmIdSt: 85, bmIdCowen: 954 },
    { bmIdSt: 86, bmIdCowen: null },
    { bmIdSt: 87, bmIdCowen: null },
    { bmIdSt: 88, bmIdCowen: null },
    { bmIdSt: 89, bmIdCowen: null },
    { bmIdSt: 90, bmIdCowen: null },
    { bmIdSt: 91, bmIdCowen: null },
    { bmIdSt: 92, bmIdCowen: 955 },
    { bmIdSt: 93, bmIdCowen: 956 },
    { bmIdSt: 94, bmIdCowen: null },
    { bmIdSt: 95, bmIdCowen: null },
    { bmIdSt: 96, bmIdCowen: null },
    { bmIdSt: 97, bmIdCowen: null },
    { bmIdSt: 98, bmIdCowen: null },
    { bmIdSt: 99, bmIdCowen: null },
    { bmIdSt: 100, bmIdCowen: null },
    { bmIdSt: 100, bmIdCowen: null },
    { bmIdSt: 101, bmIdCowen: null },
    { bmIdSt: 102, bmIdCowen: null },
    { bmIdSt: 103, bmIdCowen: 957 },
    { bmIdSt: 104, bmIdCowen: 958 },
    { bmIdSt: 105, bmIdCowen: null },
    { bmIdSt: 106, bmIdCowen: null },
    { bmIdSt: 107, bmIdCowen: 959 },
    { bmIdSt: 108, bmIdCowen: null },
    { bmIdSt: 109, bmIdCowen: 960 },
    { bmIdSt: 110, bmIdCowen: null },
    { bmIdSt: 111, bmIdCowen: null },
    { bmIdSt: 112, bmIdCowen: null },
    { bmIdSt: 113, bmIdCowen: null },
    { bmIdSt: 114, bmIdCowen: null },
    { bmIdSt: 115, bmIdCowen: 961 },
    { bmIdSt: 116, bmIdCowen: null },
    { bmIdSt: 117, bmIdCowen: null },
    { bmIdSt: 118, bmIdCowen: null },
    { bmIdSt: 119, bmIdCowen: null },
    { bmIdSt: 120, bmIdCowen: 962 },
    { bmIdSt: 121, bmIdCowen: null },
    { bmIdSt: 122, bmIdCowen: null },
    { bmIdSt: 123, bmIdCowen: null },
    { bmIdSt: 124, bmIdCowen: null },
    { bmIdSt: 125, bmIdCowen: null },
    { bmIdSt: 126, bmIdCowen: null },
    { bmIdSt: 127, bmIdCowen: null },
    { bmIdSt: 128, bmIdCowen: null },
    { bmIdSt: 129, bmIdCowen: null },
    { bmIdSt: 130, bmIdCowen: null },
    { bmIdSt: 131, bmIdCowen: null },
    { bmIdSt: 132, bmIdCowen: null },
    { bmIdSt: 133, bmIdCowen: 963 },
    { bmIdSt: 134, bmIdCowen: 964 },
    { bmIdSt: 135, bmIdCowen: null },
    { bmIdSt: 136, bmIdCowen: null },
    { bmIdSt: 137, bmIdCowen: 918 },
    { bmIdSt: 138, bmIdCowen: 965 },
    { bmIdSt: 139, bmIdCowen: 966 },
    { bmIdSt: 140, bmIdCowen: null },
    { bmIdSt: 141, bmIdCowen: null },
    { bmIdSt: 142, bmIdCowen: 967 },
    { bmIdSt: 143, bmIdCowen: null },
    { bmIdSt: 144, bmIdCowen: null },
    { bmIdSt: 145, bmIdCowen: null },
    { bmIdSt: 146, bmIdCowen: null },
    { bmIdSt: 147, bmIdCowen: null },
    { bmIdSt: 148, bmIdCowen: null },
    { bmIdSt: 149, bmIdCowen: null },
    { bmIdSt: 150, bmIdCowen: 968 },
    { bmIdSt: 151, bmIdCowen: 969 },
    { bmIdSt: 152, bmIdCowen: null },
    { bmIdSt: 153, bmIdCowen: null },
    { bmIdSt: 154, bmIdCowen: null },
    { bmIdSt: 155, bmIdCowen: null },
    { bmIdSt: 156, bmIdCowen: 970 },
    { bmIdSt: 157, bmIdCowen: 971 },
    { bmIdSt: 158, bmIdCowen: 972 },
    { bmIdSt: 159, bmIdCowen: 973 },
    { bmIdSt: 160, bmIdCowen: 974 },
    { bmIdSt: 161, bmIdCowen: null },
    { bmIdSt: 162, bmIdCowen: 975 },
    { bmIdSt: 163, bmIdCowen: 976 },
    { bmIdSt: 164, bmIdCowen: null },
    { bmIdSt: 165, bmIdCowen: null },
    { bmIdSt: 166, bmIdCowen: 977 },
    { bmIdSt: 167, bmIdCowen: null },
    { bmIdSt: 168, bmIdCowen: 978 },
    { bmIdSt: 169, bmIdCowen: 979 },
    { bmIdSt: 170, bmIdCowen: null },
    { bmIdSt: 171, bmIdCowen: 980 },
    { bmIdSt: 172, bmIdCowen: null },
    { bmIdSt: 173, bmIdCowen: 981 },
    { bmIdSt: 174, bmIdCowen: 982 },
    { bmIdSt: 175, bmIdCowen: 983 },
    { bmIdSt: 176, bmIdCowen: 984 },
    { bmIdSt: 177, bmIdCowen: 985 },
    { bmIdSt: 178, bmIdCowen: 986 },
    { bmIdSt: 179, bmIdCowen: 987 },
    { bmIdSt: 180, bmIdCowen: 988 },
    { bmIdSt: 181, bmIdCowen: 989 },
    { bmIdSt: 182, bmIdCowen: 990 },
    { bmIdSt: 183, bmIdCowen: 991 },
    { bmIdSt: 184, bmIdCowen: 992 },
    { bmIdSt: 185, bmIdCowen: 993 },
    { bmIdSt: 186, bmIdCowen: null },
    { bmIdSt: 187, bmIdCowen: 994 },
    { bmIdSt: 188, bmIdCowen: null },
    { bmIdSt: 189, bmIdCowen: 747 },
    { bmIdSt: 190, bmIdCowen: 888 },
    { bmIdSt: 191, bmIdCowen: null },
    { bmIdSt: 192, bmIdCowen: null },
    { bmIdSt: 193, bmIdCowen: 995 },
    { bmIdSt: 194, bmIdCowen: 996 },
    { bmIdSt: 195, bmIdCowen: 997 },
    { bmIdSt: 196, bmIdCowen: null },
    { bmIdSt: 197, bmIdCowen: null },
    { bmIdSt: 198, bmIdCowen: null },
    { bmIdSt: 199, bmIdCowen: null },
    { bmIdSt: 200, bmIdCowen: null }
];

export const EQUITY_RESEARCH_CANADA_MAPPING_COMPANY = [
    { compIdst: 10, compIdCowen: 3586 },
    { compIdst: 351, compIdCowen: 3569 },
    { compIdst: 357, compIdCowen: 3756 },
    { compIdst: 414, compIdCowen: 3774 },
    { compIdst: 459, compIdCowen: 3795 },
    { compIdst: 40, compIdCowen: 3603 },
    { compIdst: 3, compIdCowen: 3581 },
    { compIdst: 5, compIdCowen: 3582 },
    { compIdst: 6, compIdCowen: 3583 },
    { compIdst: 8, compIdCowen: 3584 },
    { compIdst: 9, compIdCowen: 3585 },
    { compIdst: 11, compIdCowen: 3587 },
    { compIdst: 15, compIdCowen: 3588 },
    { compIdst: 16, compIdCowen: 3589 },
    { compIdst: 17, compIdCowen: 3590 },
    { compIdst: 18, compIdCowen: 3591 },
    { compIdst: 20, compIdCowen: 3592 },
    { compIdst: 21, compIdCowen: 3593 },
    { compIdst: 24, compIdCowen: 3594 },
    { compIdst: 26, compIdCowen: 3595 },
    { compIdst: 29, compIdCowen: 3596 },
    { compIdst: 30, compIdCowen: 3597 },
    { compIdst: 31, compIdCowen: 3598 },
    { compIdst: 32, compIdCowen: 3599 },
    { compIdst: 34, compIdCowen: 3600 },
    { compIdst: 36, compIdCowen: 3601 },
    { compIdst: 38, compIdCowen: 3602 },
    { compIdst: 41, compIdCowen: 3604 },
    { compIdst: 42, compIdCowen: 3605 },
    { compIdst: 43, compIdCowen: 3606 },
    { compIdst: 44, compIdCowen: 3607 },
    { compIdst: 45, compIdCowen: 3608 },
    { compIdst: 65, compIdCowen: 3609 },
    { compIdst: 66, compIdCowen: 3610 },
    { compIdst: 67, compIdCowen: 3611 },
    { compIdst: 68, compIdCowen: 3612 },
    { compIdst: 70, compIdCowen: 3613 },
    { compIdst: 75, compIdCowen: 3614 },
    { compIdst: 76, compIdCowen: 3615 },
    { compIdst: 79, compIdCowen: 3616 },
    { compIdst: 80, compIdCowen: 3617 },
    { compIdst: 87, compIdCowen: 3618 },
    { compIdst: 94, compIdCowen: 3619 },
    { compIdst: 95, compIdCowen: 3620 },
    { compIdst: 101, compIdCowen: 3621 },
    { compIdst: 104, compIdCowen: 3622 },
    { compIdst: 105, compIdCowen: 3623 },
    { compIdst: 108, compIdCowen: 3624 },
    { compIdst: 110, compIdCowen: 3625 },
    { compIdst: 113, compIdCowen: 3626 },
    { compIdst: 114, compIdCowen: 3627 },
    { compIdst: 115, compIdCowen: 3628 },
    { compIdst: 117, compIdCowen: 3629 },
    { compIdst: 118, compIdCowen: 3630 },
    { compIdst: 119, compIdCowen: 3631 },
    { compIdst: 121, compIdCowen: 3632 },
    { compIdst: 122, compIdCowen: 3633 },
    { compIdst: 123, compIdCowen: 3634 },
    { compIdst: 126, compIdCowen: 3504 },
    { compIdst: 127, compIdCowen: 3635 },
    { compIdst: 130, compIdCowen: 3636 },
    { compIdst: 132, compIdCowen: 3637 },
    { compIdst: 133, compIdCowen: 3638 },
    { compIdst: 134, compIdCowen: 3639 },
    { compIdst: 135, compIdCowen: 3640 },
    { compIdst: 137, compIdCowen: 3641 },
    { compIdst: 138, compIdCowen: 3642 },
    { compIdst: 140, compIdCowen: 3643 },
    { compIdst: 141, compIdCowen: 3644 },
    { compIdst: 142, compIdCowen: 3645 },
    { compIdst: 143, compIdCowen: 3646 },
    { compIdst: 146, compIdCowen: 3647 },
    { compIdst: 147, compIdCowen: 3648 },
    { compIdst: 148, compIdCowen: 3649 },
    { compIdst: 150, compIdCowen: 3650 },
    { compIdst: 151, compIdCowen: 3651 },
    { compIdst: 152, compIdCowen: 3652 },
    { compIdst: 153, compIdCowen: 3653 },
    { compIdst: 156, compIdCowen: 3654 },
    { compIdst: 157, compIdCowen: 3655 },
    { compIdst: 158, compIdCowen: 3656 },
    { compIdst: 159, compIdCowen: 3657 },
    { compIdst: 160, compIdCowen: 3658 },
    { compIdst: 161, compIdCowen: 3570 },
    { compIdst: 164, compIdCowen: 3659 },
    { compIdst: 169, compIdCowen: 3660 },
    { compIdst: 172, compIdCowen: 3661 },
    { compIdst: 173, compIdCowen: 3574 },
    { compIdst: 176, compIdCowen: 3573 },
    { compIdst: 179, compIdCowen: 3662 },
    { compIdst: 181, compIdCowen: 3663 },
    { compIdst: 183, compIdCowen: 3664 },
    { compIdst: 184, compIdCowen: 3665 },
    { compIdst: 186, compIdCowen: 3666 },
    { compIdst: 187, compIdCowen: 3667 },
    { compIdst: 188, compIdCowen: 3668 },
    { compIdst: 189, compIdCowen: 3669 },
    { compIdst: 190, compIdCowen: 3670 },
    { compIdst: 191, compIdCowen: 3671 },
    { compIdst: 192, compIdCowen: 3672 },
    { compIdst: 193, compIdCowen: 3673 },
    { compIdst: 195, compIdCowen: 3674 },
    { compIdst: 197, compIdCowen: 3675 },
    { compIdst: 199, compIdCowen: 3676 },
    { compIdst: 200, compIdCowen: 3677 },
    { compIdst: 204, compIdCowen: 3678 },
    { compIdst: 206, compIdCowen: 3679 },
    { compIdst: 208, compIdCowen: 3680 },
    { compIdst: 209, compIdCowen: 3681 },
    { compIdst: 213, compIdCowen: 3682 },
    { compIdst: 215, compIdCowen: 3683 },
    { compIdst: 218, compIdCowen: 3684 },
    { compIdst: 219, compIdCowen: 3685 },
    { compIdst: 222, compIdCowen: 3686 },
    { compIdst: 223, compIdCowen: 3687 },
    { compIdst: 224, compIdCowen: 3688 },
    { compIdst: 226, compIdCowen: 3689 },
    { compIdst: 231, compIdCowen: 3690 },
    { compIdst: 232, compIdCowen: 3691 },
    { compIdst: 237, compIdCowen: 3692 },
    { compIdst: 238, compIdCowen: 3693 },
    { compIdst: 242, compIdCowen: 3694 },
    { compIdst: 243, compIdCowen: 3695 },
    { compIdst: 246, compIdCowen: 3696 },
    { compIdst: 247, compIdCowen: 3697 },
    { compIdst: 250, compIdCowen: 3698 },
    { compIdst: 252, compIdCowen: 3699 },
    { compIdst: 256, compIdCowen: 3700 },
    { compIdst: 257, compIdCowen: 3701 },
    { compIdst: 258, compIdCowen: 3702 },
    { compIdst: 261, compIdCowen: 3703 },
    { compIdst: 262, compIdCowen: 3704 },
    { compIdst: 263, compIdCowen: 3705 },
    { compIdst: 264, compIdCowen: 3706 },
    { compIdst: 268, compIdCowen: 3707 },
    { compIdst: 269, compIdCowen: 3708 },
    { compIdst: 270, compIdCowen: 3709 },
    { compIdst: 271, compIdCowen: 3710 },
    { compIdst: 272, compIdCowen: 3711 },
    { compIdst: 274, compIdCowen: 3712 },
    { compIdst: 275, compIdCowen: 3713 },
    { compIdst: 277, compIdCowen: 3714 },
    { compIdst: 278, compIdCowen: 3715 },
    { compIdst: 280, compIdCowen: 3716 },
    { compIdst: 281, compIdCowen: 3717 },
    { compIdst: 282, compIdCowen: 3718 },
    { compIdst: 284, compIdCowen: 3719 },
    { compIdst: 287, compIdCowen: 3720 },
    { compIdst: 289, compIdCowen: 3721 },
    { compIdst: 291, compIdCowen: 3722 },
    { compIdst: 292, compIdCowen: 3723 },
    { compIdst: 294, compIdCowen: 3724 },
    { compIdst: 295, compIdCowen: 3725 },
    { compIdst: 297, compIdCowen: 3726 },
    { compIdst: 298, compIdCowen: 3727 },
    { compIdst: 300, compIdCowen: 3728 },
    { compIdst: 301, compIdCowen: 3729 },
    { compIdst: 302, compIdCowen: 3730 },
    { compIdst: 303, compIdCowen: 3731 },
    { compIdst: 304, compIdCowen: 3732 },
    { compIdst: 305, compIdCowen: 3733 },
    { compIdst: 306, compIdCowen: 3734 },
    { compIdst: 307, compIdCowen: 3735 },
    { compIdst: 308, compIdCowen: 3736 },
    { compIdst: 310, compIdCowen: 3737 },
    { compIdst: 311, compIdCowen: 3738 },
    { compIdst: 312, compIdCowen: 3739 },
    { compIdst: 314, compIdCowen: 3740 },
    { compIdst: 315, compIdCowen: 3741 },
    { compIdst: 319, compIdCowen: 3742 },
    { compIdst: 320, compIdCowen: 3743 },
    { compIdst: 321, compIdCowen: 3744 },
    { compIdst: 329, compIdCowen: 3745 },
    { compIdst: 331, compIdCowen: 3746 },
    { compIdst: 334, compIdCowen: 3747 },
    { compIdst: 336, compIdCowen: 3748 },
    { compIdst: 337, compIdCowen: 3749 },
    { compIdst: 339, compIdCowen: 3750 },
    { compIdst: 340, compIdCowen: 3751 },
    { compIdst: 342, compIdCowen: 3752 },
    { compIdst: 344, compIdCowen: 3753 },
    { compIdst: 347, compIdCowen: 3754 },
    { compIdst: 354, compIdCowen: 3755 },
    { compIdst: 358, compIdCowen: 3579 },
    { compIdst: 359, compIdCowen: 3757 },
    { compIdst: 364, compIdCowen: 3758 },
    { compIdst: 371, compIdCowen: 3759 },
    { compIdst: 373, compIdCowen: 3760 },
    { compIdst: 374, compIdCowen: 3761 },
    { compIdst: 375, compIdCowen: 3762 },
    { compIdst: 378, compIdCowen: 3763 },
    { compIdst: 381, compIdCowen: 3764 },
    { compIdst: 382, compIdCowen: 3765 },
    { compIdst: 383, compIdCowen: 3766 },
    { compIdst: 385, compIdCowen: 3767 },
    { compIdst: 387, compIdCowen: 3768 },
    { compIdst: 391, compIdCowen: 3769 },
    { compIdst: 394, compIdCowen: 3770 },
    { compIdst: 404, compIdCowen: 3771 },
    { compIdst: 410, compIdCowen: 3772 },
    { compIdst: 412, compIdCowen: 3773 },
    { compIdst: 417, compIdCowen: 3775 },
    { compIdst: 418, compIdCowen: 3776 },
    { compIdst: 420, compIdCowen: 3777 },
    { compIdst: 421, compIdCowen: 3778 },
    { compIdst: 422, compIdCowen: 3779 },
    { compIdst: 423, compIdCowen: 3780 },
    { compIdst: 424, compIdCowen: 3781 },
    { compIdst: 426, compIdCowen: 3782 },
    { compIdst: 429, compIdCowen: 3783 },
    { compIdst: 431, compIdCowen: 3784 },
    { compIdst: 435, compIdCowen: 3785 },
    { compIdst: 439, compIdCowen: 3578 },
    { compIdst: 441, compIdCowen: 3786 },
    { compIdst: 446, compIdCowen: 3787 },
    { compIdst: 447, compIdCowen: 3788 },
    { compIdst: 450, compIdCowen: 3789 },
    { compIdst: 451, compIdCowen: 3790 },
    { compIdst: 452, compIdCowen: 3791 },
    { compIdst: 453, compIdCowen: 3792 },
    { compIdst: 454, compIdCowen: 3793 },
    { compIdst: 456, compIdCowen: 3794 },
    { compIdst: 464, compIdCowen: 3796 },
    { compIdst: 472, compIdCowen: 3797 },
    { compIdst: 478, compIdCowen: 3798 },
    { compIdst: 484, compIdCowen: 3799 },
    { compIdst: 486, compIdCowen: 3800 },
    { compIdst: 488, compIdCowen: 3571 },
    { compIdst: 489, compIdCowen: 3801 },
    { compIdst: 492, compIdCowen: 3802 },
    { compIdst: 493, compIdCowen: 3803 },
    { compIdst: 494, compIdCowen: 3804 },
    { compIdst: 495, compIdCowen: 3572 },
    { compIdst: 496, compIdCowen: 3805 },
    { compIdst: 499, compIdCowen: 3806 },
    { compIdst: 500, compIdCowen: 3807 },
    { compIdst: 501, compIdCowen: 3808 },
    { compIdst: 502, compIdCowen: 3809 },
    { compIdst: 503, compIdCowen: 3810 },
    { compIdst: 504, compIdCowen: 3811 },
    { compIdst: 508, compIdCowen: 3812 },
    { compIdst: 510, compIdCowen: 3813 },
    { compIdst: 513, compIdCowen: 3814 },
    { compIdst: 514, compIdCowen: 3815 },
    { compIdst: 516, compIdCowen: 3816 },
    { compIdst: 518, compIdCowen: 3817 },
    { compIdst: 523, compIdCowen: 3818 },
    { compIdst: 524, compIdCowen: 3819 },
    { compIdst: 526, compIdCowen: 3820 },
    { compIdst: 527, compIdCowen: 3821 },
    { compIdst: 528, compIdCowen: 2548 },
    { compIdst: 529, compIdCowen: 3822 },
    { compIdst: 530, compIdCowen: 3823 },
    { compIdst: 531, compIdCowen: 3824 },
    { compIdst: 532, compIdCowen: 3825 },
    { compIdst: 533, compIdCowen: 3826 },
    { compIdst: 534, compIdCowen: 3827 },
    { compIdst: 535, compIdCowen: 3828 },
    { compIdst: 536, compIdCowen: 3576 },
    { compIdst: 538, compIdCowen: 3829 },
    { compIdst: 544, compIdCowen: 3830 },
    { compIdst: 545, compIdCowen: 3831 },
    { compIdst: 546, compIdCowen: 3832 },
    { compIdst: 548, compIdCowen: 3833 },
    { compIdst: 549, compIdCowen: 3834 },
    { compIdst: 551, compIdCowen: 3580 },
    { compIdst: 552, compIdCowen: 3835 },
    { compIdst: 553, compIdCowen: 3836 },
    { compIdst: 554, compIdCowen: 3837 },
    { compIdst: 555, compIdCowen: 3838 },
    { compIdst: 556, compIdCowen: 3839 },
    { compIdst: 557, compIdCowen: 3840 },
    { compIdst: 558, compIdCowen: 3841 },
    { compIdst: 559, compIdCowen: 3842 },
    { compIdst: 560, compIdCowen: 3843 },
    { compIdst: 563, compIdCowen: 3844 },
    { compIdst: 564, compIdCowen: 3845 },
    { compIdst: 565, compIdCowen: 3846 },
    { compIdst: 566, compIdCowen: 3847 },
    { compIdst: 568, compIdCowen: 3577 },
    { compIdst: 569, compIdCowen: 3848 },
    { compIdst: 570, compIdCowen: 3849 },
    { compIdst: 571, compIdCowen: 3850 },
    { compIdst: 572, compIdCowen: 3851 },
    { compIdst: 573, compIdCowen: 3852 },
    { compIdst: 576, compIdCowen: 3853 },
    { compIdst: 577, compIdCowen: 3854 },
    { compIdst: 578, compIdCowen: 3855 },
    { compIdst: 579, compIdCowen: 3856 },
    { compIdst: 580, compIdCowen: 3857 },
    { compIdst: 582, compIdCowen: 3858 },
    { compIdst: 584, compIdCowen: 3859 },
    { compIdst: 585, compIdCowen: 3575 },
    { compIdst: 586, compIdCowen: 3860 },
    { compIdst: 587, compIdCowen: 3861 },
    { compIdst: 588, compIdCowen: 3862 },
    { compIdst: 589, compIdCowen: 3863 },
    { compIdst: 591, compIdCowen: 3864 },
    { compIdst: 594, compIdCowen: 3865 },
    { compIdst: 596, compIdCowen: 3866 },
    { compIdst: 597, compIdCowen: 3867 },
    { compIdst: 598, compIdCowen: 3868 },
    { compIdst: 599, compIdCowen: 3869 },
    { compIdst: 600, compIdCowen: 3870 },
    { compIdst: 601, compIdCowen: 3871 },
    { compIdst: 602, compIdCowen: 3872 },
    { compIdst: 603, compIdCowen: 3873 },
    { compIdst: 604, compIdCowen: 3874 },
    { compIdst: 605, compIdCowen: 3875 },
    { compIdst: 606, compIdCowen: 3876 },
    { compIdst: 607, compIdCowen: 3877 },
    { compIdst: 608, compIdCowen: 3878 },
    { compIdst: 205, compIdCowen: 3918 },
    { compIdst: 521, compIdCowen: 3911 }
];

export const EQUITY_RESEARCH_CANADA_MAPPING_INDUSTRY = [
    { indIdSt: 6, indIdCowen: 458 },
    { indIdSt: 5, indIdCowen: 457 },
    { indIdSt: 2, indIdCowen: 453 },
    { indIdSt: 20, indIdCowen: 485 },
    { indIdSt: 1, indIdCowen: 452 },
    { indIdSt: 3, indIdCowen: 454 },
    { indIdSt: 4, indIdCowen: 456 },
    { indIdSt: 7, indIdCowen: 464 },
    { indIdSt: 8, indIdCowen: 467 },
    { indIdSt: 9, indIdCowen: 469 },
    { indIdSt: 10, indIdCowen: 470 },
    { indIdSt: 11, indIdCowen: 471 },
    { indIdSt: 12, indIdCowen: null },
    { indIdSt: 13, indIdCowen: 472 },
    { indIdSt: 14, indIdCowen: 473 },
    { indIdSt: 15, indIdCowen: 476 },
    { indIdSt: 16, indIdCowen: 463 },
    { indIdSt: 17, indIdCowen: 479 },
    { indIdSt: 18, indIdCowen: 480 },
    { indIdSt: 19, indIdCowen: 483 },
    { indIdSt: 21, indIdCowen: 487 },
    { indIdSt: 22, indIdCowen: 478 },
    { indIdSt: 23, indIdCowen: null },
    { indIdSt: 24, indIdCowen: 460 },
    { indIdSt: 25, indIdCowen: 461 },
    { indIdSt: 26, indIdCowen: null },
    { indIdSt: 27, indIdCowen: 481 },
    { indIdSt: 28, indIdCowen: 474 },
    { indIdSt: 29, indIdCowen: 466 },
    { indIdSt: 30, indIdCowen: 468 },
    { indIdSt: 31, indIdCowen: 491 },
    { indIdSt: 32, indIdCowen: 490 },
    { indIdSt: 33, indIdCowen: 489 },
    { indIdSt: 34, indIdCowen: 475 },
    { indIdSt: 35, indIdCowen: null },
    { indIdSt: 36, indIdCowen: 486 },
    { indIdSt: 37, indIdCowen: 484 },
    { indIdSt: 38, indIdCowen: 459 },
    { indIdSt: 39, indIdCowen: null },
    { indIdSt: 40, indIdCowen: 477 },
    { indIdSt: 41, indIdCowen: 488 },
    { indIdSt: 42, indIdCowen: 482 },
    { indIdSt: 43, indIdCowen: 462 },
    { indIdSt: 44, indIdCowen: 465 },
    { indIdSt: 45, indIdCowen: null },
    { indIdSt: 46, indIdCowen: 455 }
];

